import React, { useState, useEffect, useCallback } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useBalance, useContractRead, useWriteContract, useWaitForTransactionReceipt, usePublicClient } from 'wagmi';
import { formatEther, parseEther } from 'viem';
import { Link } from 'react-router-dom';
import { Check, AlertCircle, X } from 'lucide-react';
import './KonkHyperMining.css';
import BlocksDisplay from './BlocksDisplay';
import { ExternalLink, ChevronDown, ChevronUp } from 'lucide-react';
import { ERC20_ABI, POOL_V2_ABI } from './abi';

// Contract Constants
const KONK_ADDRESS = '0x2b9712190Ce9570C1CA860D55B1623Bd88BF96fF';
const HYPERS_ADDRESS = '0xF8797dB8a9EeD416Ca14e8dFaEde2BF4E1aabFC3';
const POOL_V2_ADDRESS = '0x47608575afF05122637Be9315661c45Ac29cEb38';

const COMMUNITY_NFTS = {
    'richkonk': '0xb158ea657867543125fc4aec75c5128f3d6aa162', // Rich Konk Poor Konk
    'nbc': '0x06a347a6f4994fcca0c91e4261c603d8ff954242',      // NewBlastCity
    'hypersmilio': '0x7cfEdd44d6cD7805FD4489bE810995CcDFB0f122', // Hypersmilio
    'goldmilio': '0xF9D68FA74F506697Ef70F6E1E09A75BC2394E662' //goldmilio
};

// Notification Component
const TransactionNotification = ({ show, message, hash, onClose }) => {
    if (!show) return null;
    
    return (
        <div className="transaction-notification">
            <div className="notification-content">
                <div className="notification-message">
                    <Check className="success-icon" size={18} />
                    <span>{message}</span>
                </div>
                {hash && (
                    <a 
                        href={`https://blastscan.io/tx/${hash}`} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="transaction-hash"
                    >
                        View tx
                    </a>
                )}
                <button className="close-notification" onClick={onClose}>
                    <X size={16} />
                </button>
            </div>
        </div>
    );
};

// Main Component
const KONKHypersMiningV2 = () => {
    // States
    const [miningStyle, setMiningStyle] = useState('SMART RAMP');
    const [multiplier, setMultiplier] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const [selectedCommunity, setSelectedCommunity] = useState('');

    const [fixedDepositAmount, setFixedDepositAmount] = useState('0');
    const [txHash, setTxHash] = useState(null);
    const [isDepositing, setIsDepositing] = useState(false);
    const [notification, setNotification] = useState({ show: false, message: '', hash: '' });
    const [error, setError] = useState('');
    const [errorTimer, setErrorTimer] = useState(null);
    const { 
        data: receipt,
        isSuccess,
        isError,
        isPending 
    } = useWaitForTransactionReceipt({
        hash: txHash,
        enabled: !!txHash,
    });

    const [isDiscountsOpen, setIsDiscountsOpen] = useState(false);
    const [showKONKRequirementModal, setShowKONKRequirementModal] = useState(false);
    const [stKONKBalance, setStKONKBalance] = useState(null);

    const { writeContractAsync } = useWriteContract();

    const { address } = useAccount();
    const publicClient = usePublicClient();

    const [allPoolsFull, setAllPoolsFull] = useState(false);

    const { data: isPaused } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'isPaused',
        watch: true
    });

    const { data: MAX_POOLS } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'MAX_POOLS',
        watch: true
    });

    const { data: fixedFeeAmount } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'fixedFeeAmount',
        watch: true
    });

    const { data: stakerDiscount } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'stakerDiscount',
        watch: true
    });

    //community discounts

    const { data: communityDiscount } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'communityDiscounts',
        args: [selectedCommunity ? COMMUNITY_NFTS[selectedCommunity] : '0x0000000000000000000000000000000000000000'],
        watch: true,
        enabled: !!selectedCommunity
    });


    const { data: richKonkDiscount } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'communityDiscounts',
        args: [COMMUNITY_NFTS.richkonk],
        watch: true
    });
    
    const { data: nbcDiscount } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'communityDiscounts',
        args: [COMMUNITY_NFTS.nbc],
        watch: true
    });

    const { data: goldmilioDiscount } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'communityDiscounts',
        args: [COMMUNITY_NFTS.goldmilio],
        watch: true
    });
    
    const { data: hypersmilioDiscount } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'communityDiscounts',
        args: [COMMUNITY_NFTS.hypersmilio],
        watch: true
    });

    const { data: richKonkBalance } = useContractRead({
        address: COMMUNITY_NFTS.richkonk,
        abi: ERC20_ABI,
        functionName: 'balanceOf',
        args: [address],
        watch: true,
        enabled: !!address
    });
    
    const { data: nbcBalance } = useContractRead({
        address: COMMUNITY_NFTS.nbc,
        abi: ERC20_ABI,
        functionName: 'balanceOf',
        args: [address],
        watch: true,
        enabled: !!address
    });
    
    const { data: hypersmilioBalance } = useContractRead({
        address: COMMUNITY_NFTS.hypersmilio,
        abi: ERC20_ABI,
        functionName: 'balanceOf',
        args: [address],
        watch: true,
        enabled: !!address
    });

    const { data: stKONKBalanceData } = useContractRead({
        address: '0x90fC7ABB6734dF30b9dC726f6f92EefD9c3c7Bb2', // stKONK contract
        abi: ERC20_ABI,
        functionName: 'balanceOf',
        args: [address],
        watch: true,
        enabled: !!address
    });

    const { data: previousSessionHypersMined } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'previousSessionHypersMined',
        watch: true
    });

    // Add these contract reads with your other useContractRead hooks
    const { data: konkBalance } = useContractRead({
        address: KONK_ADDRESS,
        abi: ERC20_ABI,
        functionName: 'balanceOf',
        args: [address],
        watch: true,
        enabled: !!address
    });

    const { data: communityTokensData } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'communityTokens',
        watch: true
    });

    const { data: stakerDiscountAmount } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'stakerDiscount',
        watch: true
    });

    // Contract Reads
    const { data: fixedDeposit } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'fixedDeposit',
        watch: true
    });

    
    const { data: miningPoolPointer } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'miningPoolPointer',
        watch: true
    });
    
    const { data: poolInfo } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'getPoolInfo',
        args: [miningPoolPointer ? Number(miningPoolPointer) : 1],
        watch: true,
        enabled: !!miningPoolPointer
    });
    const { data: currentSessionId } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'currentSessionId',
        watch: true
    });

    const { data: miningStatus } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'getMiningStatus',
        watch: true
    });

    const { data: userInfo, refetch: refetchUserInfo } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'getUserInfo',
        args: [address]
    });

    // Add these with your other contract reads
    const { data: totalHypersMined } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'totalHypersMined',
        watch: true
    });

    const { data: totalContributions } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'totalContributions',
        watch: true
    });

    const { data: hypersBalance } = useContractRead({
        address: HYPERS_ADDRESS,
        abi: ERC20_ABI,
        functionName: 'balanceOf',
        args: [address],
        watch: true,
        enabled: !!address
    });

    const { data: contractHypersBalance } = useContractRead({
        address: HYPERS_ADDRESS,
        abi: ERC20_ABI,
        functionName: 'balanceOf',
        args: [POOL_V2_ADDRESS],  // Reading pool contract's HYPERS balance
        watch: true
    });

    const { data: poolHypersBalance } = useContractRead({
        address: HYPERS_ADDRESS,
        abi: ERC20_ABI,
        functionName: 'balanceOf',
        args: [POOL_V2_ADDRESS],
        watch: true
    });


    const { data: poolStats } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'getPoolStats',
        watch: true
    });

    const { data: allPoolStatus } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'getAllPoolStatus',
        watch: true
    });

    // Session Hypers mined
    const { data: sessionHypersMined } = useContractRead({
        address: HYPERS_ADDRESS,
        abi: ERC20_ABI,
        functionName: 'balanceOf',
        args: [POOL_V2_ADDRESS],
        watch: true
    });

    const { data: poolSize } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'poolSize',
        watch: true
    });

    // User specific data
    const { data: userPositions } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'getUserPositions',
        args: [address],
        watch: true,
        enabled: !!address
    });

    const { data: currentMiningBot } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'currentMiningBot',
        watch: true
    });
    
    // Add this hook to get bot's balance
    const { data: botBalance } = useBalance({
        address: currentMiningBot,
        watch: true,
        enabled: !!currentMiningBot,
    });

    const { data: calculatedFee } = useContractRead({
        address: POOL_V2_ADDRESS,
        abi: POOL_V2_ABI,
        functionName: 'calculateFee',
        args: [address],
        watch: true,
        enabled: !!address
    });

    // Effects

    useEffect(() => {
        const checkPoolStatus = async () => {
            try {
                if (!poolSize || !fixedDeposit || !MAX_POOLS) return;
    
                // Get total deposits from all pools
                const totalDeposits = allPoolStatus?.totalDeposits?.reduce((sum, deposit) => 
                    sum + Number(formatEther(deposit)), 0) || 0;
    
                // Calculate max capacity (poolSize * MAX_POOLS)
                const maxCapacity = Number(formatEther(poolSize)) * Number(MAX_POOLS);
    
                // Set pools full if total deposits >= max capacity
                setAllPoolsFull(totalDeposits >= maxCapacity);
    
            } catch (error) {
                console.error('Error checking pool status:', error);
            }
        };
    
        checkPoolStatus();
    }, [publicClient, poolSize, fixedDeposit, MAX_POOLS, allPoolStatus]);

    useEffect(() => {
        if (fixedDeposit) {
            setFixedDepositAmount(formatEther(fixedDeposit));
        }
    }, [fixedDeposit]);

    useEffect(() => {
        console.log('Pool Info:', poolInfo);
        console.log('HYPERS Balance:', hypersBalance);
        console.log('User Info:', userInfo);
    }, [poolInfo, hypersBalance, userInfo]);

    useEffect(() => {
        console.log('Current Session ID:', currentSessionId);
        console.log('Pool Info:', poolInfo);
        console.log('Pool Size:', poolSize);
    }, [currentSessionId, poolInfo, poolSize]);

    useEffect(() => {
        console.log('Contract Reads Debug:', {
            poolSize: poolSize ? formatEther(poolSize) : 'Not loaded',
            currentSessionId: currentSessionId?.toString() || 'Not loaded',
            address: POOL_V2_ADDRESS,
        });
    }, [poolSize, currentSessionId]);

    useEffect(() => {
        console.log('Contract State:', {
            // Pool Info
            currentPool: poolInfo ? {
                isLocked: poolInfo[0],
                totalDeposits: poolInfo[1] ? formatEther(poolInfo[1]) : '0',
                smartRampVotes: poolInfo[2] ? formatEther(poolInfo[2]) : '0',
                aggressiveVotes: poolInfo[3] ? formatEther(poolInfo[3]) : '0',
                contributorCount: poolInfo[4]?.toString() || '0',
                isCurrentlyMining: poolInfo[5],
                hypersMined: poolInfo[6] ? formatEther(poolInfo[6]) : '0'
            } : 'Not loaded',
            
            // Pool Stats
            poolSize: poolSize ? formatEther(poolSize) : 'Not loaded',
            sessionId: currentSessionId?.toString() || 'Not loaded',
            totalHypersMined: totalHypersMined ? formatEther(totalHypersMined) : '0',
            totalContributions: totalContributions?.toString() || '0',
            
            // User Position
            userPositions: userPositions ? {
                poolIds: userPositions[0]?.map(id => id.toString()),
                amounts: userPositions[1]?.map(amt => formatEther(amt)),
                isSmartRamps: userPositions[2]
            } : 'Not loaded',
            
            // HYPERS Balance
            poolHypersBalance: poolHypersBalance ? formatEther(poolHypersBalance) : '0'
        });
    }, [
        poolInfo, 
        poolSize, 
        currentSessionId, 
        totalHypersMined,
        totalContributions,
        userPositions,
        poolHypersBalance
    ]);

    useEffect(() => {
        console.log({
            poolSize: poolSize ? formatEther(poolSize) : '0',
            currentSessionId: currentSessionId?.toString(),
            poolInfo,
            poolHypersBalance: poolHypersBalance ? formatEther(poolHypersBalance) : '0'
        });
    }, [poolSize, currentSessionId, poolInfo, poolHypersBalance]);

    useEffect(() => {
        console.log('Pool Info:', poolInfo ? {
            sessionId: poolInfo[0],
            isLocked: poolInfo[1],
            totalDeposits: poolInfo[2],
            smartRampVotes: poolInfo[3],
            aggressiveVotes: poolInfo[4],
            contributorCount: poolInfo[5]
        } : 'Loading...');
        console.log('Pool Size:', poolSize);
        console.log('Session HYPERS:', sessionHypersMined);
    }, [poolInfo, poolSize, sessionHypersMined]);

    useEffect(() => {
        if (stKONKBalanceData) {
            setStKONKBalance(stKONKBalanceData);
        }
    }, [stKONKBalanceData]);

    // Helper Functions
    const showError = (message) => {
        if (errorTimer) clearTimeout(errorTimer);
        setError(message);
        const timer = setTimeout(() => setError(''), 5000);
        setErrorTimer(timer);
    };

    const calculateDepositAmount = () => {
        const baseDeposit = parseFloat(fixedDepositAmount) * multiplier;
        const baseFee = Number(formatEther(fixedFeeAmount || 0)) * multiplier;
        
        // Apply staking discount if applicable
        let finalFee = baseFee;
        if (Number(formatEther(konkBalance || 0)) >= 5555) {
            finalFee = baseFee * (1 - (Number(formatEther(stakerDiscountAmount || 0)) / 10000));
        }
        
        return baseDeposit + finalFee;
    };

    const formatErrorMessage = (error) => {
        if (error?.message?.includes('user rejected transaction')) {
            return 'Transaction was rejected';
        }
        if (error?.message?.includes('insufficient funds')) {
            return 'Insufficient balance for this transaction';
        }
        return 'Transaction failed. Please try again';
    };

    // Try reading getPoolInfo directly from contract
    const readPoolInfo = async () => {
        try {
            const data = await publicClient.readContract({
                address: POOL_V2_ADDRESS,
                abi: POOL_V2_ABI,
                functionName: 'getPoolInfo',
            });
            console.log('Direct Pool Info Read:', data);
        } catch (error) {
            console.error('Pool Info Read Error:', error);
        }
    };

    useEffect(() => {
        readPoolInfo();
    }, []);

    const checkPoolCapacity = async () => {
        try {
            const contractBalance = await publicClient.getBalance({ 
                address: POOL_V2_ADDRESS 
            });
            if (poolSize && MAX_POOLS) {
                const maxCapacity = Number(formatEther(poolSize)) * Number(MAX_POOLS);
                const isFull = Number(formatEther(contractBalance)) >= maxCapacity;
                setAllPoolsFull(isFull);
            }
        } catch (error) {
            console.error('Error checking pool capacity:', error);
        }
    };

    useEffect(() => {
        if (poolSize && MAX_POOLS) {
            checkPoolCapacity();
        }
    }, [poolSize, MAX_POOLS, publicClient]);

    const calculateTotalAmount = () => {
        // Ensure precise calculation
        const baseAmount = Number(fixedDepositAmount) * multiplier;
        const fee = Number(formatEther(calculatedFee || 0)) * multiplier;
        
        // Use toFixed to limit decimal places and parseFloat to convert back
        const totalAmount = parseFloat((baseAmount + fee).toFixed(6));
        
        return totalAmount;
    };
    

    // Handle Deposit
    const handleDeposit = async () => {
        if (!address) return;
        
        // Check KONK/stKONK balance with safe parsing
        const konkBalanceNum = konkBalance 
            ? Number(formatEther(konkBalance)) 
            : 0;
        
        const stKONKBalanceNum = stKONKBalance 
            ? Number(formatEther(stKONKBalance)) 
            : 0;
        
        if (konkBalanceNum + stKONKBalanceNum < 10000) {
            setShowKONKRequirementModal(true);
            return;
        }
        
        // Calculate deposit amounts
        const baseAmount = parseFloat(fixedDepositAmount) * multiplier;
        const fee = Number(formatEther(calculatedFee || 0)) * multiplier;
        const totalAmount = baseAmount + fee;
    
        // Debug logging
        console.log('Deposit Calculation Debug:', {
            fixedDepositAmount,
            multiplier,
            baseAmount,
            calculatedFee: Number(formatEther(calculatedFee || 0)),
            fee,
            totalAmount,
            poolSize: poolSize ? formatEther(poolSize) : 'Not loaded',
            currentPoolTotalDeposits: poolInfo?.[1] ? formatEther(poolInfo[1]) : 'Not loaded'
        });
        
        // Prepare modal message with more details
        setModalMessage(`Please confirm your deposit:
    - Base Amount: ${baseAmount.toFixed(6)} ETH
    - Fee: ${fee.toFixed(6)} ETH
    - Total Amount: ${totalAmount.toFixed(6)} ETH
    - Mining Style: ${miningStyle}
    - Multiplier: ${multiplier}x
    - Current Pool Size: ${poolSize ? formatEther(poolSize) : 'Loading...'}
    - Current Pool Deposits: ${poolInfo?.[1] ? formatEther(poolInfo[1]) : 'Loading...'}
    
    Note: Your fee includes all eligible discounts.`);
        
        // Show confirmation modal
        setShowModal(true);
    };

    useEffect(() => {
        console.log('Pool Stats:', poolStats);
        console.log('All Pool Status:', allPoolStatus);
    }, [poolStats, allPoolStatus]);

    useEffect(() => {
        console.log('Debug Values:', {
            poolInfo,
            userInfo,
            poolSize,
            currentSessionId,
            miningStatus: poolInfo?.[5],
            hypersBalance: poolInfo?.[6],
            smartRampVotes: poolInfo?.[2],
            aggressiveVotes: poolInfo?.[3]
        });
    }, [poolInfo, userInfo, poolSize, currentSessionId]);

    const confirmDeposit = async () => {
        try {
            setShowModal(false);
            setIsDepositing(true);
            
            // Use the precise calculation method
            const totalAmount = calculateTotalAmount();
            
            console.log('Exact Transaction Values:', {
                baseAmount: (Number(fixedDepositAmount) * multiplier).toFixed(6) + ' ETH',
                calculatedFee: (Number(formatEther(calculatedFee || 0)) * multiplier).toFixed(6) + ' ETH',
                totalAmount: totalAmount.toFixed(6) + ' ETH',
                multiplier: multiplier,
                exactTotalAmount: totalAmount
            });
    
            setNotification({
                show: true,
                message: 'Please confirm the transaction in your wallet',
                hash: ''
            });
    
            const hash = await writeContractAsync({
                address: POOL_V2_ADDRESS,
                abi: POOL_V2_ABI,
                functionName: 'multiDeposit',
                args: [multiplier, miningStyle === 'SMART RAMP'],
                value: parseEther(totalAmount.toFixed(6))
            });
    
            setTxHash(hash);
            
            setNotification({
                show: true,
                message: 'Transaction submitted...',
                hash
            });
    
        } catch (error) {
            console.error('Deposit Exact Error:', error);
            
            // Enhanced error logging
            console.log('Detailed Error Information:', {
                message: error.message,
                shortMessage: error.shortMessage,
                details: error.details,
                code: error.code
            });
    
            setNotification({
                message: formatErrorMessage(error),
                show: true,
                hash: ''
            });
            setIsDepositing(false);
        }
    };

    useEffect(() => {
        if (!txHash) return;
    
        if (isPending) {
            setNotification({
                show: true,
                message: 'Transaction pending...',
                hash: txHash
            });
        }
    
        if (isSuccess && receipt) {
            setNotification({
                show: true,
                message: 'Deposit successful! 🚀',
                hash: txHash
            });
            setIsDepositing(false); // Reset depositing state
            setTxHash(null);
            refetchUserInfo();
            setTimeout(() => {
                setNotification({ show: false, message: '', hash: '' });
            }, 5000);
        }
    
        if (isError) {
            setError('Transaction failed');
            setNotification({
                show: true,
                message: 'Transaction failed',
                hash: txHash
            });
            setIsDepositing(false); // Reset depositing state
            setTxHash(null);
        }
    }, [txHash, isPending, isSuccess, isError, receipt]);

    console.log('Rich Konk Discount:', richKonkDiscount);
    console.log('NBC Discount:', nbcDiscount);
    console.log('Hypersmilio Discount:', hypersmilioDiscount);

    return (
        <div className="konk-hyper-mining-v2">
            {/* Header section */}
            <header>
                <div className="header-left">
                    <Link to="/" className="home-icon">
                        <i className="fas fa-home" />
                    </Link>
                    <h1>KONK HYPERS MINING POOLS V2</h1>
                </div>
                <div className="header-right">
                    <div className="social-icons">
                        <a href="https://hypers.money" target="_blank" rel="noopener noreferrer">
                            <img src="./images/hypersound.png" alt="Hypersound" className="hypersound-icon" />
                        </a>
                        <a href="https://kiyosonk.xyz" target="_blank" rel="noopener noreferrer" className="social-icon">
                            <i className="fas fa-globe"></i>
                        </a>
                        <a href="https://x.com/kiyosonk" target="_blank" rel="noopener noreferrer" className="social-icon">
                            <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://t.me/kiyosonk" target="_blank" rel="noopener noreferrer" className="social-icon">
                            <i className="fab fa-telegram"></i>
                        </a>
                        <a href="https://kiyosonk.gitbook.io/konk-hypers-pool/" target="_blank" rel="noopener noreferrer" className="social-icon">
                            <i className="fas fa-file-alt"></i>
                        </a>
                    </div>
                    <ConnectButton />
                </div>
            </header>
    
            {/* Blocks Display */}
            <BlocksDisplay hypersAddress="0xF8797dB8a9EeD416Ca14e8dFaEde2BF4E1aabFC3" />
    
            {/* Stats Section */}
            <div className="konkhyperpool-stats">
                <h2 className="stats-title">V2 Pool Statistics</h2>
                <div className="stats-grid">
                    <div className="stat-card">
                        <div className="stat-icon">🏆</div>
                        <div className="stat-value">
                            {poolStats ? poolStats[0].toString() : '0'}
                        </div>
                        <div className="stat-label">Successful Mining Sessions</div>
                    </div>

                    <div className="stat-card">
                        <div className="stat-icon">⛏ </div>
                        <div className="stat-value">
                            {poolStats ? (parseFloat(formatEther(poolStats[1])).toFixed(3)) : '0.000'}
                        </div>
                        <div className="stat-label">Total HYPERS Mined</div>
                    </div>


                    <div className="stat-card">
                        <div className="stat-icon">💎</div>
                        <div className="stat-value">
                            {poolStats ? 
                                (Number(poolStats[2]) / 2000).toFixed(3) : '0'} ETH
                        </div>
                        <div className="stat-label">Total ETH Contributed</div>
                    </div>
                </div>
            </div>
    
            <main>
                
                {/* Current Session Info Card */}
                <div className="card">
                    <div className="card-content">
                        <div className="section-header">
                            <div className="header-info">
                                <h2>Current Session Info</h2>
                                <div className="last-session-box">
                                    {previousSessionHypersMined ? 
                                        `Last Session: ${parseFloat(formatEther(previousSessionHypersMined)).toFixed(3)} HYPERS` : 
                                        'Last Session: 0.000 HYPERS'
                                    }
                                </div>

                            </div>

                            
                            <div className="status-indicators">
                                {isPaused ? (
                                    <div className="pool-status paused">
                                        🔒 Deposits Paused
                                    </div>
                                ) : allPoolsFull ? (
                                    <div className="pool-status locked">
                                        🔒 All Pools Full ({MAX_POOLS} pools)
                                    </div>
                                ) : (
                                    <div className="pool-status open">
                                        ✅ Pools Open for Deposits
                                    </div>
                                )}
                                {poolInfo?.[5] && (
                                    <div className="mining-live-status">
                                        <span className="pulse-dot"></span>
                                        Mining is Live
                                    </div>
                                )}
                            </div>
                        </div>
                        
                        <div className="session-stats">

                        <div className="stat-item">
                                <div className="stat-label">SESSION ID</div>
                                <div className="stat-value">
                                    #{currentSessionId ? currentSessionId.toString() : '0'}
                                </div>
                            </div>


                            <div className="stat-item">
                                <div className="stat-label">SESSION SIZE</div>
                                <div className="stat-value">
                                    {poolSize ? formatEther(poolSize) : '0'} ETH
                                </div>
                            </div>

                            <div className="stat-item">
                                <div className="stat-label">REMAINING CAPACITY</div>
                                <div className="stat-value">
                                    {poolSize && poolInfo ? 
                                        `${(Number(formatEther(poolSize)) - Number(formatEther(poolInfo[1]))).toFixed(4)} ETH` 
                                        : '0 ETH'}
                                </div>
                            </div>


                            <div className={`stat-item ${poolInfo?.[5] && Number(poolInfo[2]) >= Number(poolInfo[3]) ? 'winner-glow' : ''}`}>
                                <div className="stat-label">SMART RAMP VOTES</div>
                                <div className="stat-value">
                                    {poolInfo?.[2] && fixedDeposit ? 
                                        Math.floor(Number(formatEther(poolInfo[2])) / Number(formatEther(fixedDeposit))) : '0'
                                    } Votes
                                </div>
                            </div>

                            <div className={`stat-item ${poolInfo?.[5] && Number(poolInfo[2]) < Number(poolInfo[3]) ? 'winner-glow' : ''}`}>
                                <div className="stat-label">SMART AGGRESSIVE VOTES</div>
                                <div className="stat-value">
                                    {poolInfo?.[3] && fixedDeposit ? 
                                        Math.floor(Number(formatEther(poolInfo[3])) / Number(formatEther(fixedDeposit))) : '0'
                                    } Votes
                                </div>
                            </div>

                            <div className="stat-item">
                                <div className="stat-label">TOTAL CONTRIBUTORS</div>
                                <div className="stat-value">
                                    {poolInfo?.[4]?.toString() || '0'}
                                </div>
                            </div>

                            <div className="stat-item">
                                <div className="stat-label">HYPERS MINED THIS SESSION</div>
                                <div className="stat-value">
                                    {poolInfo?.[5] ? 
                                        `${Number(formatEther(contractHypersBalance || 0)).toFixed(3)} HYPERS` : 
                                        'Mining is not live'
                                    }
                                </div>
                            </div>

                            <div className="stat-item">
                                <div className="stat-label">YOUR PARTICIPATION</div>
                                <div className="stat-value">
                                {(() => {
                                    // Find user's deposit in current mining pool
                                    const currentPoolDeposit = userPositions && userPositions[0]?.length > 0 
                                        ? Number(formatEther(userPositions[1][userPositions[0].findIndex(id => 
                                            Number(id) === Number(miningPoolPointer))] || 0))
                                        : 0;

                                    if (currentPoolDeposit > 0) {
                                        return (
                                            <div className="participation-info">
                                                <span className="amount">{currentPoolDeposit.toFixed(4)} ETH</span>
                                                <span className="style">
                                                    {userPositions && userPositions[0]?.length > 0 && 
                                                    userPositions[2][userPositions[0].findIndex(id => 
                                                        Number(id) === Number(miningPoolPointer))]
                                                        ? 'Smart Ramp'
                                                        : 'Aggressive'
                                                    }
                                                </span>
                                            </div>
                                        );
                                    }
                                    return 'No';
                                })()}
                                </div>
                            </div>

                            <div className="stat-item">
                                <a 
                                    href="https://blastscan.io/address/0xdb14eed121138c4c44f2bd2441980cec80539df9" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none' }} // Optional: to remove underline
                                >
                                    <div className="stat-label">BOT WALLET</div>
                                    <div className="stat-value">
                                        {currentMiningBot && currentMiningBot !== "0x0000000000000000000000000000000000000000" ? 
                                            `${currentMiningBot.slice(0, 6)}...${currentMiningBot.slice(-4)}` : 
                                            'No Bot Selected'
                                        }
                                    </div>
                                </a>
                            </div>
                                <div className="stat-item">
                                    <div className="stat-label">REMAINING BOT FUNDS</div>
                                    <div className="stat-value">
                                        {currentMiningBot && currentMiningBot !== "0x0000000000000000000000000000000000000000" ? 
                                            `${(Number(formatEther(botBalance?.value || 0))-0.0125).toFixed(4)} ETH` : 
                                            '0 ETH'
                                        }
                                    </div>
                                </div>       
                        </div>
                    </div>
                </div>


                {/* Your Position Overview */}
                <div className="card">
                    <h2>Your Position Overview</h2>
                    <div className="position-grid">
                        <div className="position-item">
                            <div className="position-label">CURRENT SESSION DEPOSITS</div>
                            <div className="position-value">
                            {userPositions && userPositions[0]?.length > 0 
                                ? formatEther(userPositions[1][userPositions[0].findIndex(id => 
                                    Number(id) === Number(miningPoolPointer))] || 0) 
                                : '0'} ETH
                            </div>
                        </div>

                        <div className="position-item">
                            <div className="position-label">HYPERS REWARDS IN THIS SESSION</div>
                            <div className="position-value">
                                {(() => {
                                    // Get current pool deposit
                                    const currentPoolDeposit = userPositions && userPositions[0]?.length > 0 
                                        ? Number(formatEther(userPositions[1][userPositions[0].findIndex(id => 
                                            Number(id) === Number(miningPoolPointer))] || 0))
                                        : 0;

                                    // Get total pool deposits
                                    const totalPoolDeposits = poolInfo ? Number(formatEther(poolInfo[1] || 0)) : 0;

                                    // Get contract HYPERS balance
                                    const hypersInContract = contractHypersBalance ? 
                                        Number(formatEther(contractHypersBalance)) : 0;

                                    // Calculate user's share
                                    if (currentPoolDeposit > 0 && totalPoolDeposits > 0 && hypersInContract > 0) {
                                        const userShare = (currentPoolDeposit / totalPoolDeposits) * hypersInContract;
                                        return `${userShare.toFixed(4)} HYPERS`;
                                    }
                                    
                                    return '0 HYPERS';
                                })()}
                            </div>
                        </div>

                        <div className="position-item">
                            <div className="position-label">TOTAL ETH DEPOSITS</div>
                            <div className="position-value">
                                {userPositions && userPositions[1]?.length > 0 
                                    ? userPositions[1].reduce((sum, amount) => 
                                        sum + Number(formatEther(amount)), 0).toFixed(4)
                                    : '0'} ETH
                            </div>
                        </div>
                        
                        <div className="position-item">
                            <div className="position-label">HYPERS BALANCE</div>
                            <div className="position-value">
                                {hypersBalance ? Number(formatEther(hypersBalance)).toFixed(4) : '0'} HYPERS
                            </div>
                        </div>

                        {userPositions && userPositions[0]?.length > 0 && (
                            <>
                                <div className="position-item full-width">
                                    <div className="position-label">YOUR UPCOMING SESSIONS</div>
                                    <div className="position-value sessions">
                                        {userPositions && userPositions[0]?.length > 0 && currentSessionId && miningPoolPointer ? (
                                            userPositions[0].map(poolId => {
                                                const numericPoolId = Number(poolId);
                                                const numericMiningPool = Number(miningPoolPointer);
                                                const numericSessionId = Number(currentSessionId);
                                                
                                                let sessionOffset;
                                                if (numericPoolId >= numericMiningPool) {
                                                    // For pools ahead of mining pool
                                                    sessionOffset = numericPoolId - numericMiningPool;
                                                } else {
                                                    // For pools that wrapped around to the beginning
                                                    sessionOffset = (Number(MAX_POOLS) - numericMiningPool) + numericPoolId;
                                                }
                                                
                                                const participatingSession = numericSessionId + sessionOffset;
                                                
                                                return `#${participatingSession}`;
                                            }).join(', ')
                                        ) : '0'}
                                    </div>
                                </div>

                                <div className="position-item full-width">
                                    <div className="position-label">YOUR DEPOSITS PER SESSION</div>
                                    <div className="deposits-list">
                                        {userPositions && userPositions[0]?.length > 0 && currentSessionId && miningPoolPointer ? (
                                            userPositions[0].map((poolId, index) => {
                                                const numericPoolId = Number(poolId);
                                                const numericMiningPool = Number(miningPoolPointer);
                                                const numericSessionId = Number(currentSessionId);
                                                
                                                let sessionOffset;
                                                if (numericPoolId >= numericMiningPool) {
                                                    sessionOffset = numericPoolId - numericMiningPool;
                                                } else {
                                                    sessionOffset = (Number(MAX_POOLS) - numericMiningPool) + numericPoolId;
                                                }
                                                
                                                const participatingSession = numericSessionId + sessionOffset;
                                                
                                                return (
                                                    <div key={poolId.toString()} className="deposit-item">
                                                        <span className="session-id">Session #{participatingSession}</span>
                                                        <span className="amount">{formatEther(userPositions[1][index])} ETH</span>
                                                        <span className={`style ${userPositions[2][index] ? 'smart' : 'aggressive'}`}>
                                                            {userPositions[2][index] ? 'Smart Ramp' : 'Aggressive'}
                                                        </span>
                                                    </div>
                                                );
                                            })
                                        ) : null}
                                    </div>
                                </div>
                            </>
                        )}

                        {/* Show HYPERS rewards only if user is in current session */}
                        {userInfo && Number(formatEther(userInfo)) > 0 && poolInfo?.hypersMined && (
                            <div className="position-item full-width">
                                <div className="position-label">YOUR HYPERS REWARDS THIS SESSION</div>
                                <div className="position-value">
                                {(() => {
                                        const totalDeposits = Number(formatEther(poolInfo[1] || 0));  // Using poolInfo[1] for totalDeposits
                                        const userDeposit = userPositions && userPositions[0]?.length > 0 
                                            ? Number(formatEther(userPositions[1][userPositions[0].findIndex(id => 
                                                Number(id) === Number(miningPoolPointer))] || 0))
                                            : 0;
                                        const totalHypers = Number(formatEther(contractHypersBalance || 0));
                                        if (totalDeposits > 0 && userDeposit > 0) {
                                            const userShare = (userDeposit / totalDeposits) * totalHypers;
                                            return `${userShare.toFixed(4)} HYPERS`;
                                        }
                                        return '0 HYPERS';
                                    })()}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
    
                {/* Deposit Controls */}
                <div className="card">
                    <div className="card-content">
                        <h2>Make Deposit</h2>

                        {/* Add this before the Mining Style Selection */}
                        <div className="discounts-section">
                            <div 
                                className="discounts-header" 
                                onClick={() => setIsDiscountsOpen(!isDiscountsOpen)}
                            >
                                <h3>Available Discounts</h3>
                                <button className="discounts-toggle">
                                    {isDiscountsOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                                </button>
                            </div>

                            {isDiscountsOpen && (
                                <div className="discounts-content">
                                    <div className="discount-list">
                                        <div className="discount-item">
                                            <div className="discount-status">
                                                {Number(formatEther(konkBalance || 0)) >= 1000 && <span className="active-indicator">✓</span>}
                                            </div>
                                            <div className="discount-info">
                                                <div className="discount-details">
                                                    <span className="discount-title">🌟 10000 + KONK Staking:</span>
                                                    <span className="discount-value">
                                                        {stakerDiscount ? `${(Number(stakerDiscount) / 100)}% off fees` : 'Loading...'}
                                                    </span>
                                                </div>
                                                <a 
                                                    href="https://app.kiyosonk.xyz/konk-staking" 
                                                    target="_blank" 
                                                    rel="noopener noreferrer" 
                                                    className="stake-konk-button"
                                                    onClick={(e) => e.stopPropagation()}
                                                >
                                                    <ExternalLink size={16} /> Stake KONK
                                                </a>
                                            </div>
                                        </div>

                                        <h3 className="additional-discounts">Additional Community Discounts</h3>
                                        <div className="discount-list">
                                            <div className="discount-item">
                                                <div className="discount-status">
                                                    {richKonkDiscount?.[2] && Number(richKonkBalance || 0) > 0 && 
                                                        <span className="active-indicator">✓</span>
                                                    }
                                                </div>
                                                <div className="discount-info">
                                                    <span className="discount-title">📕 Rich Konk Poor Konk:</span>
                                                    <span className="discount-value">
                                                        {richKonkDiscount?.[2] ? 
                                                            `${Number(richKonkDiscount[1]) / 100}% off fees` : 
                                                            'Loading...'}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="discount-item">
                                                <div className="discount-status">
                                                    {nbcDiscount?.[2] && Number(nbcBalance || 0) > 0 && 
                                                        <span className="active-indicator">✓</span>
                                                    }
                                                </div>
                                                <div className="discount-info">
                                                    <span className="discount-title">🌆 NewBlastCity:</span>
                                                    <span className="discount-value">
                                                        {nbcDiscount?.[2] ? 
                                                            `${Number(nbcDiscount[1]) / 100}% off fees` : 
                                                            'Loading...'}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="discount-item">
                                                <div className="discount-status">
                                                    {goldmilioDiscount?.[2] && Number(goldmilioDiscount || 0) > 0 && 
                                                        <span className="active-indicator">✓</span>
                                                    }
                                                </div>
                                                <div className="discount-info">
                                                    <span className="discount-title">🧏‍♂️ Goldmilio:</span>
                                                    <span className="discount-value">
                                                        {goldmilioDiscount?.[2] ? 
                                                            `${Number(goldmilioDiscount[1]) / 100}% off fees` : 
                                                            'Loading...'}
                                                    </span>
                                                </div>
                                            </div>

                                            <div className="discount-item">
                                                <div className="discount-status">
                                                    {hypersmilioDiscount?.[2] && Number(hypersmilioBalance || 0) > 0 && 
                                                        <span className="active-indicator">✓</span>
                                                    }
                                                </div>
                                                <div className="discount-info">
                                                    <span className="discount-title">🧙 Hypersmilio:</span>
                                                    <span className="discount-value">
                                                        {hypersmilioDiscount?.[2] ? 
                                                            `${Number(hypersmilioDiscount[1]) / 100}% off fees` : 
                                                            'Loading...'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="discount-note">* Community NFT discounts are automatically applied. The highest eligible discount will be selected.</p>
                                </div>
                            )}
                        </div>
                        
                        {/* Mining Style Selection */}
                        <div className="mining-styles">
                            <div className="mining-style-option">
                                <button
                                    onClick={() => setMiningStyle('SMART RAMP')}
                                    className={`style-button smart-ramp ${miningStyle === 'SMART RAMP' ? 'active' : ''}`}
                                >
                                    Smart Ramp
                                </button>
                                <div className="style-description">
                                    Batch Size: Auto Adjusts<br/>
                                    MAX COST PER BLOCK: ~=Theoretical value<br/> 
                                    It tries to mine at an economic rate.
                                </div>
                            </div>
                            
                            <div className="mining-style-option">
                                <button
                                    onClick={() => setMiningStyle('AGGRESSIVE')}
                                    className={`style-button aggressive ${miningStyle === 'AGGRESSIVE' ? 'active' : ''}`}
                                >
                                    Smart Aggressive
                                </button>
                                <div className="style-description">
                                    BATCH SIZE: Auto Adjusts<br/>
                                    MAX COST PER BLOCK: ~15% more than the theoretical value.<br/> 
                                    It tries to stay ahead of others.
                                </div>
                            </div>
                        </div>

                        
    
                        {/* Multiplier Control */}
                        <div className="multiplier-control">
                            <div className="multiplier-label">
                                Deposit Multiplier (1x = {fixedDepositAmount} ETH)
                            </div>
                            <input
                                type="range"
                                min="1"
                                max="50"
                                value={multiplier}
                                onChange={(e) => setMultiplier(parseInt(e.target.value))}
                                className="multiplier-slider"
                            />
                            <div className="multiplier-value">
                                {multiplier}x = {(parseFloat(fixedDepositAmount) * multiplier).toFixed(6)} ETH
                                <span className="fee-info">
                                    + {(parseFloat(fixedDepositAmount) * multiplier * (Number(formatEther(fixedFeeAmount || 0)))).toFixed(6)} ETH fee
                                </span>
                            </div>
                        </div>
    
                        {/* User Position Info */}
                        {address && userInfo && parseFloat(formatEther(userInfo)) > 0 && (
                            <div className="user-position">
                                <h3>Your Current Position</h3>
                                <div className="position-details">
                                    <div className="detail-item">
                                        <span className="label">Deposited:</span>
                                        <span className="value">{formatEther(userInfo)} ETH</span>
                                    </div>
                                    {miningStatus?.miningInProgress && (
                                        <div className="mining-indicator">
                                            Mining in progress...
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
    
                        {/* Deposit Button */}
                        <button
                            onClick={handleDeposit}
                            disabled={!address || isDepositing || miningStatus?.miningInProgress}
                            className="deposit-button"
                        >
                            {!address ? (
                                'Connect Wallet to Deposit'
                            ) : isDepositing ? (
                                <span className="loading-text">
                                    <span className="spinner"></span>
                                    Depositing...
                                </span>
                            ) : miningStatus?.miningInProgress ? (
                                'Mining in Progress - Deposits Paused'
                            ) : (
                                `Deposit ${(parseFloat(fixedDepositAmount) * multiplier * 1.025).toFixed(6)} ETH`
                            )}
                        </button>
    
                        {error && (
                            <div className="error-message">
                                <AlertCircle size={16} />
                                <span>{error}</span>
                            </div>
                        )}
                    </div>
                </div>
    
                {/* Confirmation Modal */}
                {showModal && (
                    <div className="modal-overlay">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h3 className="modal-title">Confirm Deposit</h3>
                            </div>
                            <div className="modal-body">
                                <pre className="modal-message">{modalMessage}</pre>
                            </div>
                            <div className="modal-footer">
                                <button 
                                    className="modal-button button-cancel"
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                                <button 
                                    className="modal-button button-confirm"
                                    onClick={confirmDeposit}
                                >
                                    Confirm Deposit
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showKONKRequirementModal && (
                    <div className="modal-overlay">
                        <div className="modal-content konk-requirement-modal">
                            <div className="modal-header">
                                <h3>Insufficient KONK Balance</h3>
                            </div>
                            <div className="modal-body">
                                <p>You don't have enough KONK to use the pool.</p>
                                <p className="minimum-requirement">
                                    Minimum requirements: 10,000 KONK 
                                    (Liquid or Staked)
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button 
                                    className="modal-button button-cancel"
                                    onClick={() => setShowKONKRequirementModal(false)}
                                >
                                    Close
                                </button>
                                <a 
                                    href="https://app.thruster.finance/?token2=0x2b9712190Ce9570C1CA860D55B1623Bd88BF96fF"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="modal-button button-confirm"
                                >
                                    Buy KONK
                                </a>
                            </div>
                        </div>
                    </div>
                )}
    
                {/* Transaction Notification */}
                <TransactionNotification 
                    show={notification.show}
                    message={notification.message}
                    hash={notification.hash}
                    onClose={() => setNotification({ show: false, message: '', hash: '' })}
                />
            </main>
    
            {/* Footer */}
            <footer className="konkhypers-footer">
                <div className="konkhypers-footer-content">
                    <img 
                        src="/images/blastlogo.svg" 
                        alt="Built on Blast" 
                        className="konkhypers-blast-logo" 
                    />
                </div>
            </footer>
        </div>
    );
};

export default KONKHypersMiningV2;