import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useContractRead, usePublicClient } from 'wagmi';
import { formatEther } from 'viem';

const BATCH_MINERS_ADDRESS = '0x041Ea15f507D1eB956081E3804843EEb6098C3e9';
const BLOCKS_PER_PAGE = 20;
const BATCH_SIZE = 10;

const HYPERS_ABI = [
    {
        "inputs": [{"internalType": "uint256", "name": "blockNumber", "type": "uint256"}],
        "name": "minersPerBlockCount",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {"internalType": "uint256", "name": "blockNumber", "type": "uint256"},
            {"internalType": "uint256", "name": "index", "type": "uint256"}
        ],
        "name": "minersPerBlock",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "blockNumber",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "lastBlockTime",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "miningReward",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "anonymous": false,
        "inputs": [
            {"indexed": false, "internalType": "uint256", "name": "blockNumber", "type": "uint256"},
            {"indexed": false, "internalType": "address", "name": "miner", "type": "address"}
        ],
        "name": "NewBlock",
        "type": "event"
    }
];

const BATCH_MINERS_ABI = [{
    "inputs": [
        {"internalType": "address", "name": "hypersContract", "type": "address"},
        {"internalType": "uint256", "name": "blockNumber", "type": "uint256"}
    ],
    "name": "aggregateMiners",
    "outputs": [{
        "components": [
            {"internalType": "address", "name": "minerAddress", "type": "address"},
            {"internalType": "uint256", "name": "mineCount", "type": "uint256"}
        ],
        "internalType": "struct BatchMiners.MinerInfo[]",
        "name": "",
        "type": "tuple[]"
    }],
    "stateMutability": "view",
    "type": "function"
}];

const formatAddress = (address) => {
    if (!address || address === 'pending') return 'Pending...';
    const knownAddresses = {
        '0xb82619C0336985e3EDe16B97b950E674018925Bb': 'KONKPool',
        '0xdB14eEd121138c4C44F2Bd2441980CeC80539Df9': 'KONKPool',
        '0xD89FA564fFeaFA49797b05A3C81eD79fD4E1Ea7A': 'KONKPool',
        '0x72aeF46A2b46fd078530A1A61E18BF68ff78EB0C': 'Rufina',
        '0x4a5073642E143a9Ef35af6DED4d45bB711A27096': 'Rufina',
        '0x4a76905d6aabfd8d62e723b6c848b26a2fbe2dfb': 'KONKPool',
        '0x07Db507B73E5E34c5155144195238Cc87570575': 'Rufina'
    };
    return knownAddresses[address?.toLowerCase()] || `${address.slice(0, 6)}...${address.slice(-4)}`;
};

const formatTimeAgo = (timestamp) => {
    if (!timestamp) return 'Unknown';
    const seconds = Math.floor(Date.now() / 1000 - timestamp);
    
    if (seconds < 60) return `${seconds}s ago`;
    if (seconds < 3600) return `${Math.floor(seconds / 60)}m ago`;
    if (seconds < 86400) return `${Math.floor(seconds / 3600)}h ago`;
    return `${Math.floor(seconds / 86400)}d ago`;
};

const BlockDisplay = ({ blockNumber, minerCount, winner, miner, reward, blockTime, isActive, onClick }) => {
    return (
        <div className={`mining-block ${isActive ? 'active' : ''}`} onClick={onClick}>
            <div className="block-decoration"></div>
            <div className="block-number">#{blockNumber}</div>
            <div className="block-miner-count">
                {minerCount}
                <span className="mdi mdi-pickaxe"></span>
            </div>
            {/* <div className="block-winner" title="Block reward winner">
                {winner === 'pending' ? (
                    <small className="text-secondary">Pending winner...</small>
                ) : (
                    <>{formatAddress(winner)}</>
                )}
            </div> */}
            <div className="block-reward">
                {reward ? `${formatEther(reward)} HYPERS` : ''}
                <br />
                {formatTimeAgo(blockTime)}
            </div>
            <div className="block-miner" title="Block issuer">
                {formatAddress(miner)}
            </div>
        </div>
    );
};

const BlocksDisplay = ({ hypersAddress }) => {
    const [blocks, setBlocks] = useState([]);
    const [selectedBlock, setSelectedBlock] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [reachedEnd, setReachedEnd] = useState(false);
    const scrollContainerRef = useRef(null);
    const [blockCache] = useState(new Map());
    const publicClient = usePublicClient();
    const NEW_BLOCK_TOPIC = '0x58ab9d8b9ae9ad7e2baee835f3d3fe920b93baf574a51df42c0390491f7297e9';
    const [cachedBlockTimes, setCachedBlockTimes] = useState({});
    const [earliestBlockFetched, setEarliestBlockFetched] = useState(null);
    const [latestBlockFetched, setLatestBlockFetched] = useState(null);
    let cachedWinners = {};
    let cachedMiners = {};
    let cachedEvents = {};

    // Add these after your existing const declarations
const getNewBlockEvent = useCallback(async (blockNumber) => {
    try {
        const logs = await publicClient.getLogs({
            address: hypersAddress,
            topics: [NEW_BLOCK_TOPIC],
            fromBlock: blockNumber,
            toBlock: blockNumber
        });
        
        return logs[0] || null;
    } catch (error) {
        console.error(`Error fetching block ${blockNumber} event:`, error);
        return null;
    }
}, [publicClient, hypersAddress]);

const getBlockMinerWinner = useCallback(async (blockNumber) => {
    if(!cachedMiners[blockNumber]) {
        const event = await getNewBlockEvent(blockNumber);
        if (!event) return { winner: 'pending', miner: 'pending' };
        const tx = await publicClient.getTransaction({
            hash: event.transactionHash
        });
        const block = await publicClient.getBlock({
            blockNumber: tx.blockNumber
        });
        cachedMiners[blockNumber] = tx.from;
        cachedBlockTimes[blockNumber] = block.timestamp;
    }
    
    const winner = cachedWinners[blockNumber] ? cachedWinners[blockNumber] : 'pending';
    const miner = cachedMiners[blockNumber];
    return { winner, miner };
}, [publicClient, getNewBlockEvent]);

    const { data: currentBlock } = useContractRead({
        address: hypersAddress,
        abi: HYPERS_ABI,
        functionName: 'blockNumber',
        watch: true,
        cacheTime: 15_000
    });

    const { data: miningReward } = useContractRead({
        address: hypersAddress,
        abi: HYPERS_ABI,
        functionName: 'miningReward',
        cacheTime: 60_000
    });

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const getBlockMiners = useCallback(async (blockNumber) => {
        try {
            const miners = await publicClient.readContract({
                address: BATCH_MINERS_ADDRESS,
                abi: BATCH_MINERS_ABI,
                functionName: 'aggregateMiners',
                args: [hypersAddress, blockNumber]
            });

            return miners.reduce((acc, { minerAddress, mineCount }) => {
                acc[minerAddress] = Number(mineCount);
                return acc;
            }, {});
        } catch (error) {
            console.error(`Error fetching miners for block ${blockNumber}:`, error);
            return {};
        }
    }, [publicClient, hypersAddress]);

    const getBlockTime = useCallback(async (blockNumber) => {
        try {
            if (blockNumber === Number(currentBlock) + 1) {
                const lastBlockTime = await publicClient.readContract({
                    address: hypersAddress,
                    abi: HYPERS_ABI,
                    functionName: 'lastBlockTime'
                });
                return Math.floor(Date.now() / 1000) - Number(lastBlockTime);
            }
            return Math.floor(Date.now() / 1000) - (Number(currentBlock) - blockNumber) * 60;
        } catch (error) {
            console.error(`Error getting block time for block ${blockNumber}:`, error);
            return null;
        }
    }, [publicClient, hypersAddress, currentBlock]);  

    const getBlockInfo = useCallback(async (blockNum) => {
        try {
            if (blockCache.has(blockNum)) {
                return blockCache.get(blockNum);
            }
    
            await delay(100);
    
            const minerCount = await publicClient.readContract({
                address: hypersAddress,
                abi: HYPERS_ABI,
                functionName: 'minersPerBlockCount',
                args: [blockNum]
            });
    
            if (minerCount > 0) {
                // Get last miner of current block (block issuer)
                const miner = await publicClient.readContract({
                    address: hypersAddress,
                    abi: HYPERS_ABI,
                    functionName: 'minersPerBlock',
                    args: [blockNum, Number(minerCount) - 1]
                });
    
                // Get first miner of next block for winner
                const nextBlockMiners = await publicClient.readContract({
                    address: hypersAddress,
                    abi: HYPERS_ABI,
                    functionName: 'minersPerBlockCount',
                    args: [blockNum + 1]
                });
    
                let winner = 'pending';
                if (nextBlockMiners > 0) {
                    winner = await publicClient.readContract({
                        address: hypersAddress,
                        abi: HYPERS_ABI,
                        functionName: 'minersPerBlock',
                        args: [blockNum + 1, 0]
                    });
                }
    
                const blockTime = Math.floor(Date.now() / 1000) - (Number(currentBlock) - blockNum) * 60;
    
                const blockData = {
                    blockNumber: blockNum,
                    minerCount: Number(minerCount),
                    winner,
                    miner,
                    reward: miningReward,
                    blockTime
                };
    
                blockCache.set(blockNum, blockData);
                return blockData;
            }
            return null;
        } catch (error) {
            console.error(`Error fetching block ${blockNum} info:`, error);
            return null;
        }
    }, [publicClient, hypersAddress, miningReward, blockCache, currentBlock]);

    const loadMoreBlocks = useCallback(async () => {
        if (isLoadingMore || reachedEnd || blocks.length === 0) return;
        
        setIsLoadingMore(true);
        const oldestBlock = Math.min(...blocks.map(b => b.blockNumber));
        
        try {
            const newBlocks = [];
            for (let i = 1; i <= BATCH_SIZE; i++) {
                const blockNum = oldestBlock - i;
                if (blockNum <= 0) {
                    setReachedEnd(true);
                    break;
                }
                
                const block = await getBlockInfo(blockNum);
                if (block) {
                    newBlocks.push(block);
                    await delay(200);
                }
            }
    
            if (newBlocks.length > 0) {
                setBlocks(prev => {
                    const combined = [...prev, ...newBlocks];
                    const sorted = combined.sort((a, b) => b.blockNumber - a.blockNumber);
                    return sorted.slice(0, BLOCKS_PER_PAGE * 2);
                });
            }
        } catch (error) {
            console.error('Error loading more blocks:', error);
        } finally {
            setIsLoadingMore(false);
        }
    }, [blocks, isLoadingMore, reachedEnd, getBlockInfo]);

    useEffect(() => {
        if (!currentBlock) {
            console.log("No current block");
            return;
        }
        console.log("Current block:", currentBlock);
    
        let isMounted = true;
    
        const loadInitialBlocks = async () => {
            console.log("Starting to load initial blocks");
            setIsLoading(true);
            const blockData = [];
            const startBlock = Number(currentBlock);
    
            for (let i = 0; i < BLOCKS_PER_PAGE; i++) {
                const blockNum = startBlock - i;
                if (blockNum <= 0) break;
    
                console.log("Fetching block:", blockNum);
                const block = await getBlockInfo(blockNum);
                console.log("Block data received:", block);
                if (block && isMounted) {
                    blockData.push(block);
                }
            }
    
            if (isMounted) {
                console.log("Setting blocks:", blockData);
                setBlocks(blockData.sort((a, b) => b.blockNumber - a.blockNumber));
                setIsLoading(false);
            }
        };
    
        loadInitialBlocks();
    
        return () => {
            isMounted = false;
        };
    }, [currentBlock, getBlockInfo]);

    useEffect(() => {
        const container = scrollContainerRef.current;
        if (!container) return;
    
        const handleScroll = () => {
            const { scrollLeft, scrollWidth, clientWidth } = container;
            const isNearEnd = scrollWidth - (scrollLeft + clientWidth) < clientWidth * 0.2;
            
            if (isNearEnd && !isLoadingMore && !reachedEnd) {
                loadMoreBlocks();
            }
        };
    
        container.addEventListener('scroll', handleScroll);
        return () => container.removeEventListener('scroll', handleScroll);
    }, [loadMoreBlocks, isLoadingMore, reachedEnd]);

    return (
        <div className="blocks-section">
            <div className="blocks-container" ref={scrollContainerRef}>
                <div className="blocks-scroll">
                    <BlockDisplay
                        blockNumber={Number(currentBlock || 0) + 1}
                        minerCount={0}
                        winner="pending"
                        miner="pending"
                        reward={miningReward}
                        blockTime={null}
                        isActive={selectedBlock === Number(currentBlock || 0) + 1}
                        onClick={() => setSelectedBlock(
                            selectedBlock === Number(currentBlock || 0) + 1 ? null : Number(currentBlock || 0) + 1
                        )}
                    />
                    
                    {blocks.map((block) => (
                        <BlockDisplay
                            key={block.blockNumber}
                            {...block}
                            isActive={selectedBlock === block.blockNumber}
                            onClick={() => setSelectedBlock(
                                selectedBlock === block.blockNumber ? null : block.blockNumber
                            )}
                        />
                    ))}

                    {isLoading && blocks.length === 0 && (
                        <div className="loading-blocks">Loading blocks...</div>
                    )}
                    {isLoadingMore && (
                        <div className="loading-more">Loading more blocks...</div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BlocksDisplay;